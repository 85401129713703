import React, { useEffect } from 'react';
import { useIntercom } from 'react-use-intercom';
import { globalHistory } from '@reach/router';

const IntercomHandler = ({ hidden }) => {
  const { show, hide } = useIntercom();
  const PATHNAME = globalHistory?.location?.pathname;
  const ROUTE_NAME = 'client-support';

  useEffect(() => {
    show();
    return globalHistory.listen(({ action }) => {
      if (action === 'PUSH') hide();
    });
  }, [hide, show]);

  useEffect(() => {
    if (hidden && PATHNAME?.includes(ROUTE_NAME)) show();
  }, [hidden, show, PATHNAME, ROUTE_NAME]);

  return <section className="intercom-section flex-grow-1" />;
};

export default IntercomHandler;
