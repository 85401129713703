const lookForCloseButton = () => {
  return setInterval(() => {
    const iframe = document.querySelector('[name="intercom-messenger-frame"]');
    if (iframe) {
      const ele = iframe.contentWindow.document.body.querySelector(
        '[aria-label="Close"]',
      );
      if (ele) {
        ele.remove();
      }
    }
  }, 500);
};

export default lookForCloseButton;
export { lookForCloseButton };
