import React, { useEffect, useRef, useState } from 'react';
import { navigate } from 'gatsby';
import { IntercomProvider } from 'react-use-intercom';
import Seo from '../components/seo';
import IntercomHandler from '../components/intercom';
import lookForCloseButton from '../components/intercom/util-close';

const INTERCOM_APP_ID = 'fbfz1hri';
const INTERCOM_API_BASE = 'https://api-iam.intercom.io';

const ClientSupport = () => {
  const [isHidden, setHidden] = useState(false);
  const [userProps, setUserProps] = useState({});
  const interval = useRef();

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const userId = urlParams.get('userId');
    const createdAt = urlParams.get('createdAt');
    const email = urlParams.get('email');
    const source = urlParams.get('source');

    if (!source) navigate('/');

    setUserProps({ email, userId, createdAt });
  }, []);

  const hanleHideToggle = (hidden) => () => {
    setHidden(hidden);
  };

  useEffect(() => {
    interval.current = lookForCloseButton();
    return () => interval.current && clearInterval(interval.current);
  }, []);

  return (
    <>
      <Seo data={{ seo: { seo_title: 'Client Support' } }} />
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        autoBoot
        apiBase={INTERCOM_API_BASE}
        onHide={hanleHideToggle(true)}
        onShow={hanleHideToggle(false)}
        autoBootProps={{
          hideDefaultLauncher: true,
          ...userProps,
        }}
      >
        <IntercomHandler hidden={isHidden} />
      </IntercomProvider>
    </>
  );
};

export default ClientSupport;
